<template>
    <div v-if="screnValidate">
        <GetContent 
            v-bind:dataFeed="dataFeed" 
            v-bind:unrefreshable="unrefreshable"
            v-on:contentGetted="contentGetted" 
            v-on:contentReset="contentReset"
        ></GetContent>        
        <b-button 
            class="app-sidebar-control position-fixed d-flex align-items-center justify-content-center rounded-0" 
            variant="primary"
            v-b-toggle.app-sidebar-left
        >
            <b-icon icon="chevron-double-right"></b-icon>
        </b-button>
        <b-sidebar 
            id="app-sidebar-left" 
            title="Sidebar" 
            shadow 
            no-header 
            no-close-on-route-change 
            :visible="sidebarShow" 
            class="app-sidebar"
            bg-variant="light"
        >
            <!-- шапка сайдбара -->
            <div class="app-sidebar-left__header fixed-top bg-secondary pb-1">
                <b-button 
                    v-b-toggle.app-sidebar-left 
                    class="d-flex align-items-center justify-content-between w-100 rounded-0"
                    variant="primary"
                    size="sm"
                    >
                    <span>Закрыть панель</span>
                    <b-icon icon="x"></b-icon>
                </b-button>
            </div>
            <!-- контент сайдбара -->
            <!-- bg-info h-100 -->
            <div>
                <div v-if="contentReady" :class="contentWrapClass+' app-block-append'">
                    <OverlayBlockItem v-for="(item, index) in blockItems"
                        v-bind:key = index
                        v-bind:item = item
                        v-bind:siteContent="siteContent"
                        v-bind:vertical="true"
                        v-on:itemClick="itemClick"                        
                    >
                    </OverlayBlockItem>                        
                    <!-- маркер прокрутки -->
                    <div v-if="infinity" class="w-100 d-block" style="height: 1px;" v-b-visible="itemsIncrease"></div>
                </div>
                <div v-else :class="contentWrapClass+' app-preloader-append'">
                    <OverlayBlockPreloader v-for="(item, index) in blockPreloader"
                        v-bind:key="index"
                        v-bind:siteContent="siteContent"
                        v-bind:vertical="true"
                    ></OverlayBlockPreloader>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import { BSidebar, BButton } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import GetContent from '../general/GetContent.vue'
import OverlayBlockItem from './OverlayBlockItem'
import OverlayBlockPreloader from './OverlayBlockPreloader'
import utils from '../../functions/functions'

export default {
    components: {
        GetContent,
        OverlayBlockItem,
        OverlayBlockPreloader,
        BSidebar, 
        BButton
    },
    data(){
        return {
            sidebarShow: false, // false
            blockPreloader: ['', '', '', '', '', ''], 
            blockItems: [],
            blockItemsLocked: [],  
            contentWrapClass: 'd-flex flex-column px-2 pt-3'          
        }
    },
    props: ['goalName', 'limit', 'dataFeed', 'unrefreshable'],    
    computed: {
        ...mapGetters(['SCREEN_SIZE', 'REFRESHING', 'LAST_ROUTE']),
        screnValidate: function(){
            return (this.SCREEN_SIZE === 7)
        },
        contentReady: function(){
            // && !this.REFRESHING
            return (this.blockItems.length) ? true : false
        },
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        },  
        infinity: function(){
            return (!this.limit) ? true : false
        }
    },
    methods: {
        ...mapActions(['FEED_SUM_DEFINE', 'FETCH_SMI24', 'FETCH_INFOX', 'SMI2_FETCH']),
        arrayClone: function(arr){
            this.blockItems = []
            const fullArr = utils.shuffleArray(arr)
            const fullLength = fullArr.length            
            const limit = (this.limit) ? this.limit : fullLength
            this.blockItems = fullArr.slice(0, limit)
            this.blockItemsLocked = fullArr.slice(0, limit)
        },
        contentReset: function(){
            this.blockItems = []
        },        
        itemsIncrease: function(isVisible){
            if (isVisible && this.blockItemsLocked.length){
                const newArr = utils.shuffleArray(this.blockItemsLocked)
                const limit = (this.limit) ? this.limit : newArr.length
                for (let i=0; i < limit; i++){
                    this.blockItems.push(newArr[i])
                }
            }
        },                
        contentGetted: function(arr){
            this.arrayClone(arr)
        },
        itemClick: function(){
            const vueContext = this
            if (this.goalName){
                utils.safeGoal(this.goalName, vueContext)
                utils.safeGoal('GoLink', vueContext)
            }
        },                        
    },
    beforeMount: function(){
        // isHome
        const vueContext = this
        if (utils.isHome(vueContext) && !this.LAST_ROUTE){
            this.FEED_SUM_DEFINE(3)
            const vueContext = this
            this.FETCH_SMI24(vueContext)
            this.FETCH_INFOX(vueContext)
            this.SMI2_FETCH(vueContext)        
        }
    },
    mounted: function(){
        const vueContext = this
        window.setTimeout(function(){
            vueContext.sidebarShow = true
        }, 8000)
    },
    watch: {
        contentReady: function(){
            // console.log('this.contentReady', this.contentReady)
        }
    }
}
</script>

<style lang="sass">
.app-sidebar
    .b-sidebar
        width: 280px
        top: 43px
        height: calc(100vh - 43px)
.app-sidebar-left__header
    top: -43px !important       
.app-sidebar-control
    top: 0
    bottom: 0
    width: 50px
    z-index: 1021

.b-sidebar-body::-webkit-scrollbar
    width: 8px !important
    background-color: #dae0e5//#17a2b8  //#23272b
    box-shadow: -4px 0px 5px -6px rgba(0, 0, 0, 1)
.b-sidebar-body::-webkit-scrollbar-thumb
    width: 8px
    background-color: gray  //#17a2b8     
    border-radius: 10px         
</style>