<template>
  <div>
    <GetContent 
      v-bind:dataFeed="dataFeed" 
      v-bind:unrefreshable="unrefreshable"
      v-on:contentGetted="contentGetted"
      v-on:contentReset="contentReset"
    ></GetContent>
    <div :class="classSliderContainer">
      <div class="app-custom-slider-wrap d-flex overflow-hidden" :id="sliderId">
        <!-- <transition name="fade-append"> -->
          <div v-if="contentReady" class="app-slider-content app-block-append d-flex" :style="sliderContentMargin">
            <CarouselBlockItem
              v-for="(item, index) in blockItems"
              v-bind:key="index"
              v-bind:index="index"
              v-bind:item="item"
              v-bind:siteContent="siteContent"
              v-bind:uniqId="sliderId"
              v-bind:noName="noName"
              v-on:itemClick="itemClick"
              v-on:itemsMounted="itemsMounted"
            ></CarouselBlockItem>
          </div>
        <!-- </transition> -->
        <div v-else class="app-slider-content app-preloader-append d-flex">
          <CarouselBlockPreloader
            v-for="(item, index) in preloaderItems"
            v-bind:key="index"
            v-bind:siteContent="siteContent"
          ></CarouselBlockPreloader>
        </div>
      </div>
      <!-- контролы -->
      <transition name="fade">
        <div v-if="sliderConrtols" :class="classControlLeft">
          <b-button :variant="buttonVariant" :disabled="controlLeftDisable" @click="leftControl" class="rounded-circle">
            <b-icon icon="arrow-left" aria-hidden="true"></b-icon>
          </b-button>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="sliderConrtols" :class="classControlRight">
          <b-button :variant="buttonVariant" :disabled="sliderEnd"  @click="rightControl" class="rounded-circle">
            <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
          </b-button>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import CarouselBlockItem from './CarouselBlockItem'
import CarouselBlockPreloader from './CarouselBlockPreloader'
import GetContent from '../general/GetContent.vue'
import utils from '../../functions/functions'

export default {
  components: {
    GetContent,
    BButton,
    CarouselBlockItem,
    CarouselBlockPreloader    
  },  
  data(){
    return {
      sliderId: '',
      offsetX: 0, //отрицательный margin-left
      feedLength: 0,
      wrapLength: 0,
      slideWidth: 0,
      sliderEnd: false,
      realOffset: 0,
      blockItems: [],
      //здесь заранее знаем
      preloaderItems: ['', '', '', '', '', '', '', '', '', ''],
      controlShow: false
    }
  },
  props: ['goalName', 'limit', 'dataFeed', 'fullWidth', 'unrefreshable', 'noName'],
  computed: {
    ...mapGetters(['SCREEN_SIZE', 'REFRESHING']),
    siteContent: function(){
      return (this.dataFeed === 'site') ? true : false
    },
    slidePerPage: function(){
      return (this.SCREEN_SIZE >=5) ? 5 : 4 // ПЕРЕДЕЛАТЬ ПОД КАЖДОЕ ЗНАЧЕНИЕ ШИРИНЫ
    },
    //показывать или нет контролы в зависимости от ширины экрана
    sliderConrtols: function(){
      return (this.controlShow && this.SCREEN_SIZE >= 6 && !this.REFRESHING) ? true: false
    },
    //левый контрол дизейбл
    controlLeftDisable: function(){
      return (!this.offsetX) ? true : false
    },
    //правый контрол дизейбл
    controlRightDisable: function(){
      return (this.sliderEnd) ? true : false
    },
    sliderContentMargin: function(){
      return `margin-left:-${this.offsetX}px`
    },
    contentReady: function(){
      // return (this.blockItems.length && !this.REFRESHING) ? true : false
      return (this.blockItems.length) ? true : false
    },
    classSliderContainer: function(){
      const classBase = 'mt-3 position-relative '
      const pX = (this.fullWidth) ? 'px-5' : ''
      return classBase + pX
    },
    classSliderWrap: function(){
      const classBase = ' '
      const pX = (this.fullWidth) ? 'px-5' : ''
      return classBase + pX
    },
    classControlLeft: function(){
      const classBase = 'position-absolute align-items-center '
      const classModifier = (this.fullWidth) ? 'app-scroll-control__left-full' : 'app-scroll-control__left'
      return classBase + classModifier
    },
    classControlRight: function(){
      const classBase = 'position-absolute align-items-center '
      const classModifier = (this.fullWidth) ? 'app-scroll-control__right-full' : 'app-scroll-control__right'
      return classBase + classModifier
    },
    buttonVariant: function(){
      return (this.fullWidth) ? 'light' : 'dark'
    }
  },
  methods: {
    arrayClone: function(arr){
        this.blockItems = []
        const shuffled = utils.shuffleArray(arr)            
        this.blockItems = shuffled.slice(0, this.limit)
    },
    contentReset: function(){
      this.blockItems = []
    },
    contentGetted: function(arr){
        this.arrayClone(arr)
    },    
    itemClick: function(){
      if (this.goalName){
        const vueContext = this
        utils.safeGoal(this.goalName, vueContext)
        utils.safeGoal('GoLink', vueContext)
      }
    },
    sliderInit: function(){
      let sliderContentWidth = 0
      const slides = document.getElementsByClassName('app-slide-'+this.sliderId)
      this.slideWidth = slides[0].clientWidth + 20
      for (let i=0; i<slides.length; i++){
        sliderContentWidth = sliderContentWidth + this.slideWidth
      }
      this.feedLength = sliderContentWidth
      //'slider-wrap'
      this.wrapLength = document.getElementById(this.sliderId).offsetWidth
    },
    sliderReset: function(){
      this.offsetX = 0
      this.feedLength = 0
      this.wrapLength = 0
      this.slideWidth = 0
      this.sliderEnd = false
      this.realOffset = 0      
    },
    rightControl: function(){
      const sum = this.offsetX + this.slidePerPage*this.slideWidth
      //заранее чекаем следующую перемотку
      const sumNext = this.offsetX + this.slidePerPage*this.slideWidth*2
      //def должен быть больше, чем this.wrapLength
      //если меньше, то this.offsetX вычисляется по другой формуле:  Ширина обертки - Общая ширина фида
      const def = this.feedLength - sum
      //пока сумма не превышает длину слайдер-контента, присваиваем сумму, иначе оставляем прошлое значение
      this.offsetX = (sum <= this.feedLength) ? sum : this.offsetX
      //сначала запоминаем реальное смещение, от которого потом будем минусовать
      this.realOffset = (def <= this.wrapLength) ? this.offsetX : 0
      //воизбежание пустых отступов справа
      this.offsetX = (def <= this.wrapLength) ? (this.feedLength - this.wrapLength) : this.offsetX
      //дизейблим кнопку
      this.sliderEnd = (sumNext >= this.feedLength) ? true : false
    },
    leftControl: function(){
      //если есть realOffset - минусуем от него
      const sum = (!this.realOffset) ? (this.offsetX - this.slidePerPage*this.slideWidth) : (this.realOffset - this.slidePerPage*this.slideWidth)
      //обнуляем this.realOffset
      this.realOffset = 0
      //заранее чекаем следующую перемотку
      const sumNext = this.offsetX + this.slidePerPage*this.slideWidth*2      
      this.offsetX = (sum >= 0) ? sum : this.offsetX
      //раздизейбливаем кнопку
      this.sliderEnd = false
    },
    itemsMounted: function(payload){
      if (payload === this.blockItems.length-1){
        this.controlShow = true
        // this.sliderReset()
        this.sliderInit()
      }      
    }

  },
  created: function(){
    const fullId = utils.uuidv4()
    const fullIdParts = fullId.split('-')
    this.sliderId = fullIdParts[fullIdParts.length-1]
    const vueContext = this
    window.addEventListener('resize', function(){
      vueContext.sliderReset()
      vueContext.sliderInit()
    })    
  },
  mounted: function(){

  },
  watch: {
    blockItems: function(){
      //сбрасываем только если роут-нэйм не равно home
      if (this.$route.name != 'home'){
        this.sliderReset()
      }
    },
  }
}
</script>

<style lang="sass">
.app-slider-content
  overflow-x: scroll
  transition: all 0.6s
  scrollbar-width: none

.app-slider__scrollbar-hide
  margin-top: -16px
  height: 16px

.app-scroll-control__left
  width: 57px
  top: 0
  bottom: 0
  left: -78px
  display: none
.app-scroll-control__right
  width: 57px
  top: 0
  bottom: 0
  right: -78px
  display: none  
.app-scroll-control__left-full
  width: 57px
  top: 0
  bottom: 0  
  left: 32px
  display: none  
.app-scroll-control__right-full
  width: 57px
  top: 0
  bottom: 0  
  right: 32px
  display: none


@media (min-width: 1360px)
  .app-scroll-control__left,
  .app-scroll-control__right,
  .app-scroll-control__left-full,
  .app-scroll-control__right-full
    display: flex
    button
      height: 57px  
</style>