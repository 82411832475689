<template>
    <div>
        <!-- навбар -->
        <div v-if="emptyBlock.show" class="d-block w-100" :style="emptyBlock.style"></div>
        <b-navbar variant="dark" :fixed="fixed" :class="navClass">
            <!-- название -->
            <div :class="brandWrapClass" style="left:0">
                <!-- mt-sm-0 py-0 mt-2 -->
                <b-navbar-brand class="app-nav-brand text-uppercase text-decoration-none" :to="linkTo" :href="linkHref" @click="headerClick">
                    <b-icon icon="globe2" class="mr-2 text-light"></b-icon>
                    <span class="text-light">
                        Главные&nbsp;
                    </span>
                    <span class="text-info">
                        Новости
                    </span>
                </b-navbar-brand>
            </div>

            <!-- кнопка -->
            <div v-if="SCREEN_SIZE > 1" class="position-relative px-2">
                <NavLineButton></NavLineButton>
            </div>
        </b-navbar>
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { mapGetters } from 'vuex'
import { BNavbar, BNavbarBrand, BLink } from 'bootstrap-vue'
import NavLineButton from './NavLineButton.vue'
export default {
    components: {
        BNavbar,
        BNavbarBrand, 
        BLink,
        NavLineButton
    },
    computed: {
        ...mapGetters(['SCREEN_SIZE', 'PLATFORM']),
        emptyBlock: function(){
            const vueContext = this
            //определяем - показывать или нет
            let show = false
            if (utils.isRotator(vueContext)){
                show = (this.SCREEN_SIZE > 1) ? true : false
            }else{
                //только на десктопах
                show = (this.PLATFORM === 'desktop') ? true : false
            }
            //определяем высоту
            const height = (this.SCREEN_SIZE < 3) ? 70 : 58
            const result = {
                show: show,
                style: `height:${height}px;`
            }
            return result
        },
        navClass: function(){
            const classBase = 'px-0 d-flex flex-column flex-sm-row flex-md-row-reverse align-items-stretch justify-content-sm-between align-items-sm-center '
            const vueContext = this
            const paddingBottom = (utils.isRotator(vueContext) && this.SCREEN_SIZE === 1) ? 'pb-0' : ''
            return classBase + paddingBottom
        },
        fixed: function(){
            let result = null
            const vueContext = this
            if (utils.isRotator(vueContext)){
                //здесь зависит от ширины
                result = (this.SCREEN_SIZE > 1) ? 'top' : null
            }else{
                //а здесь только на десктопах
                result = (this.PLATFORM === 'desktop') ? 'top' : null
            }
            return result
        },
        linkTo: function(){
            const vueContext = this
            return (utils.isPost(vueContext)) ? '/' : null
        },
        linkHref: function(){
            const vueContext = this
            let href = null
            if (utils.isHome(vueContext)){
                href = '/'
            }else if (utils.isRotator(vueContext)){
                href = '/'+this.$route.name
            }
            return href
        },
        //чтобы было посередине
        brandWrapClass: function(){
            return (this.SCREEN_SIZE > 2) ? 'position-absolute w-100 h-100 d-flex justify-content-center align-items-center' : 'px-2'
        },
    },
    methods: {
        headerClick: function(e){
            const vueContext = this
            if (!utils.isRotator(vueContext) && utils.hasReferrer()){
                e.preventDefault()
                utils.safeGoal('toHome', vueContext)
                utils.setFullscreen()
            }
        }
    },
    created: function(){
        const vueContext = this
        window.addEventListener('resize', function(){
            if (vueContext.SCREEN_SIZE > 1){
                vueContext.scrolled = false
            }
        })
    }
}
</script>


<style lang="sass" scoped>
nav
    z-index: 1019   
    min-height: 58px
a         
    transition: all 0.2s
</style>