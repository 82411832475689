<template>
    <div>
        <!-- слайдер -->
        <!-- все подключаем без условий -->
        <!-- прелоадер заинклюжен в самом компоненте -->
        <!-- pt-sm-5 -->
        <section class="bg-light d-flex justify-content-center align-items-center">
            <b-container>
                <b-row>
                    <b-col>
                        <CarouselBlock 
                            v-bind:goalName="'CarouselBlock'"
                            v-bind:dataFeed="'mixed'"
                            class="mb-3"
                        >
                        </CarouselBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- текстовый блок -->
        <section class="pt-3">
            <b-container>
                <b-row>
                    <b-col>
                        <TextBlock
                            v-bind:goalName="'TextBlock'"
                            v-bind:dataFeed="'mixed'"
                            v-bind:limit="10"
                            v-bind:icon="'newspaper'"
                            ></TextBlock>            
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- блок "текст поверх картинки" -->
        <!-- v-bind:limit="0" значит на всю длину -->
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlock'"
                            v-bind:infinity="true"
                            v-bind:limit="0"
                            v-bind:dataFeed="'smi24'"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import RotatorNav from '../components/rotator/RotatorNav'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import TextBlock from '../components/integrations/TextBlock'
import OverlayBlock from '../components/integrations/OverlayBlock'

export default {
    data(){
        return {
        }
    },
    components: {
        BContainer, 
        BRow, 
        BCol,
        RotatorNav,
        CarouselBlock,
        SimpleBlock,
        TextBlock,
        OverlayBlock,
    },
    computed: {

    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'FETCH_INFOX', 'FEED_SUM_DEFINE', 'FEED_COUNT_RESET']),
    },
    created: function(){
        this.FEED_COUNT_RESET()
        const vueContext = this
        this.FEED_SUM_DEFINE(2)
        this.FETCH_SMI24(vueContext)
        this.FETCH_INFOX(vueContext)
    },
    watch: {

    }
}
</script>

<style lang="sass">

</style>