<template>
    <div>
        <p>
            {{ text }}
        </p>        
        <b-alert
            v-if="(!isDirectHit || !isProd) && !lastP"
            :show="true"
            variant="info"
            
        >
            <TextBlock
                v-bind:limit="1"
                v-bind:dataFeed="'mixed'"                        
                v-bind:goalName="'inTextSingle'"
                v-bind:icon="'newspaper'"
                v-bind:fontWeight="'semi-bold'"
                v-bind:tooltip="true"
            ></TextBlock>            
        </b-alert>        
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { BAlert } from 'bootstrap-vue'
import TextBlock from '../integrations/TextBlock.vue'
export default {
    components: {
        BAlert,
        TextBlock
    },
    props: ['text', 'index', 'max'],
    computed: {
        isDirectHit: function(){
            return !utils.hasReferrer()
        },
        lastP: function(){
            const max = this.max - 1
            return (this.index === max) ? true : false
        },
        isProd: function(){
            return utils.isProd()
        }
    }
}
</script>
