import { render, staticRenderFns } from "./CarouselBlockItem.vue?vue&type=template&id=4ec8ac04&scoped=true"
import script from "./CarouselBlockItem.vue?vue&type=script&lang=js"
export * from "./CarouselBlockItem.vue?vue&type=script&lang=js"
import style0 from "./CarouselBlockItem.vue?vue&type=style&index=0&id=4ec8ac04&prod&lang=sass"
import style1 from "./CarouselBlockItem.vue?vue&type=style&index=1&id=4ec8ac04&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ec8ac04",
  null
  
)

export default component.exports