<template>
    <div class="flex-grow-1">
        <b-button v-if="isRotator || (isHome && hasRef) || STANDALONE"
            variant="primary" 
            size="sm" 
            :disabled="this.REFRESHING" 
            @click="newsUpdate" 
            :class="buttonClass"
        >
            <span class="mr-2">Обновить</span>
            <b-icon icon="arrow-clockwise" style="transform: rotate(45deg)"></b-icon>                
        </b-button>

        <!-- <b-button v-else-if="!SUBSCRIBE" 
            variant="primary" 
            size="sm" 
            :disabled="buttonDisable" 
            :class="'app-install-trigger '+buttonClass"
            @click="subscribeClick"
        >
            <b-icon :icon="icon" class="mr-2"></b-icon>
            <span>Подписаться</span>
        </b-button> -->

        <!-- :class="'app-install-trigger '+buttonClass" -->
        <b-button v-if="isPost"
            variant="primary" 
            size="sm" 
            :disabled="buttonDisable" 
            :class="buttonClass"
            @click="postUpdate"
        >
            <!-- <b-icon icon="circle-fill" class="mr-2" animation="throb"></b-icon> -->
            <!-- fade -->
            <!-- variant="danger" -->
             <!-- <b-icon icon="circle-fill" class="mr-3" font-scale="0.75" :animation="animation"></b-icon> -->
            <span>Следующая новость</span>
            <b-icon :icon="icon" class="ml-2"></b-icon>

            <PostPrefetch
                v-if="MIXED_READY && nextId"
                v-bind:loadContent="true"
                v-bind:loadFull="true"
                v-bind:postId="nextId"
            ></PostPrefetch>            
        </b-button>        
    </div>    
</template>

<script>
import utils from '../../functions/functions'
import { mapGetters, mapActions } from 'vuex'
import {  BButton } from 'bootstrap-vue'
import PostPrefetch from '../content/PostPrefetch.vue'
export default {
    components: {
        BButton,
        PostPrefetch
    },
    data(){
        return {
            buttonClass: 'd-flex justify-content-center align-items-center',
            currentId: utils.getIdFromAddressBar(),
            indexOfNextPost: 0,
            idCollection: [],
            buttonAnimation: false,
            infoxToPostTransition: false,
        }
    },    
    computed: {
        ...mapGetters(['FLOW_READY', 'SUBSCRIBE', 'REFRESHING', 'ACTIVE_NOW', 'POSTS', 'MIXED_READY', 'INFOX', 'STANDALONE']),
        isRotator: function(){
            const vueContext = this
            return utils.isRotator(vueContext)
        },   
        isHome: function()     {
            const vueContext = this
            return utils.isHome(vueContext)
        },
        isPost: function(){
            const vueContext = this
            return utils.isPost(vueContext)            
        },
        hasRef: function(){
            return utils.hasReferrer()
        },
        refreshButtonCondition: function(){
            const vueContext = this
            return (utils.isRotator(vueContext) || (utils.isHome(vueContext) && utils.hasReferrer()))
        },
        buttonDisable: function(){
            //return (!this.FLOW_READY) ? true : false
            // return (this.idCollection.length && this.MIXED_READY) ? false : true
            return (this.INFOX.length) ? false : true
        },
        icon: function(){
            //return (this.FLOW_READY) ? 'bell-fill' : 'chevron-double-right' //'lock-fill'
            return 'chevron-double-right' //'lock-fill'
        },
        nextId: function(){
            return (this.idCollection.length) ? this.idCollection[this.indexOfNextPost] : undefined
        },
        animation: function(){
            return (this.buttonAnimation) ? 'throb' : null
        }
    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'FETCH_INFOX', 'SMI2_FETCH', 'RESET_ALL', 'FEED_SUM_DEFINE', 'FEED_COUNT_RESET', 'RESET_POST', 'SET_POST', 
        'FETCH_LIST', 'FETCH_POST', 'TEXT_STATE_SET']),        
        newsUpdate: function(){
            this.RESET_ALL()
            this.FEED_COUNT_RESET()
            const feedSum = (this.isRotator) ? 2 : 3
            this.FEED_SUM_DEFINE(feedSum)
            const vueContext = this
            utils.safeGoal('ButtonClick', vueContext)
            this.FETCH_SMI24(vueContext)
            this.FETCH_INFOX(vueContext)
            if (this.isHome){
                this.SMI2_FETCH(vueContext)
            }
        }, 
        postUpdate: function(){
            //сабмитим цель
            const vueContext = this
            utils.safeGoal('NextPost', vueContext)
            //схлопываем контент-блок
            if (utils.fullScreenAbility()){
                this.TEXT_STATE_SET(false)
            }
            //определяем ид
            // const nextPostID = this.idCollection[this.indexOfNextPost]
            //обновляем пост
            this.RESET_POST(true)
            this.infoxToPostTransition = true
            //this.FETCH_POST(this.nextId, 'force-cache')
            //пушим роутер
            // this.$router.push(`/post/?p=${this.nextId}`)            
            //инкрементим индекс
            // this.indexOfNextPost = (this.indexOfNextPost != this.idCollection.length) ? this.indexOfNextPost + 1 : 0
            // console.log('indexOfNextPost после инкремента', this.indexOfNextPost)
            //обновляем тизерки
            this.RESET_ALL()
            this.FEED_COUNT_RESET()
            this.FEED_SUM_DEFINE(3)
            this.FETCH_SMI24(vueContext)
            this.FETCH_INFOX(vueContext)
            this.SMI2_FETCH(vueContext)
            if (utils.fullScreenAbility() && utils.hasReferrer()){
                const vueContext = this
                utils.setFullscreen()
                utils.safeGoal('marker1', vueContext)
            }
            //убираем анимацию иконки
            this.buttonAnimation = false
        },
        subscribeClick: function(){
            const vueContext = this
            utils.safeGoal('SubscribeButton', vueContext)
        },
    },
    mounted: function(){
        /*
        // this.buttonAnimate()
        const vueContext = this
        window.setInterval(function(){
            vueContext.buttonAnimate()
        }, 5000)
        */
    },
    watch: {
        MIXED_READY: function(newVal, oldVal){
            if (newVal && !oldVal){
                if (!this.POSTS.length){
                    //лист фетчим один раз
                    this.FETCH_LIST()
                }
            }
        },
        INFOX: function(){
            if (this.infoxToPostTransition && this.INFOX.length){
                const arr = utils.shuffleArray(this.INFOX)
                const randomItem = arr[0]
                const post = {
                    title: randomItem.title,
                    text: [randomItem.body],
                    image: randomItem.img,
                    url: randomItem.url
                }
                this.SET_POST(post)

            }
        },
        POSTS: function(){
            this.buttonAnimation = (this.POSTS.length) ? true : this.buttonAnimation
            if (!this.idCollection.length){
                for (let i=0; i<this.POSTS.length; i++){
                    if (this.POSTS[i].id != this.currentId){
                        this.idCollection.push(this.POSTS[i].id)
                    }
                }
            }
        },
        buttonDisable: function(){
            this.buttonAnimation = (this.buttonDisable) ? false : this.buttonAnimation
        }
    }
}
</script>

<style lang="sass" scoped>
button
    width: 100%
    span
        font-weight: 500
</style>