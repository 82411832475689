<template>
    <div></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            dataGetted: false,
        }
    },
    props: ['dataFeed', 'unrefreshable'],
    computed: {
        ...mapGetters(['INFOX', 'SMI24', 'SMI2', 'MIXED', 'MIXED_READY', 'POSTS'])
    },
    methods: {
        contentGetted(arr){
            if (this.unrefreshable){
                if (!this.dataGetted){
                    this.$emit('contentGetted', arr)
                }
            }else{
                this.$emit('contentGetted', arr)
            }
            this.dataGetted = true
        }
    },
    mounted: function(){

        if (this.dataFeed === 'infox' && this.INFOX.length){
            this.contentGetted(this.INFOX)
        }        
        if (this.dataFeed === 'smi24' && this.SMI24.length){
            this.contentGetted(this.SMI24)
        }    
        if (this.dataFeed === 'smi2' && this.SMI2.length){
            this.contentGetted(this.SMI2)
        } 
        if (this.dataFeed === 'mixed' && this.MIXED_READY  && this.MIXED.length){
            this.contentGetted(this.MIXED)
        } 
        if (this.dataFeed === 'site' && this.POSTS.length){
            this.contentGetted(this.POSTS)
        }                          
    },
    watch: {
        INFOX: function(){
            if (this.dataFeed === 'infox'){
                this.contentGetted(this.INFOX)
            }
            if (!this.INFOX.length && !this.unrefreshable){
                this.$emit('contentReset')
            }            
        },
        SMI24: function(){
            if (this.dataFeed === 'smi24'){
                this.contentGetted(this.SMI24)
            }
            if (!this.SMI24.length && !this.unrefreshable){
                this.$emit('contentReset')
            }
        },
        SMI2: function(){
            if (this.dataFeed === 'smi2'){
                this.contentGetted(this.SMI2)
            }
            if (!this.SMI2.length && !this.unrefreshable){
                this.$emit('contentReset')
            }
        },                
        MIXED: function(){
            if (this.dataFeed === 'mixed' && this.MIXED_READY){
                this.contentGetted(this.MIXED)
            }
            if (!this.MIXED.length && !this.unrefreshable){
                this.$emit('contentReset')
            }
        },
        POSTS: function(){
            if (this.dataFeed === 'site'){
                this.contentGetted(this.POSTS)
            }
        }, 
    }        
}
</script>
