import Vue from 'vue'
import Vuex from 'vuex'
import fullscreenState from '../modules/fullscreenState'
import content from '../modules/content'
import integrations from '../modules/integrations'
import screen from '../modules/screen'
import devices from '../modules/devices'
import flow from '../modules/flow'
import routeTracking from '../modules/routeTracking'
import api from '../modules/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    fullscreenState,
    content,
    integrations,
    screen,
    devices,
    flow,
    routeTracking,
    api,
  }
})
