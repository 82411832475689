<template>
    <div v-if="indicateShow" class="position-fixed bg-danger d-flex align-items-center d-flex justify-content-center align-items-center">
        <b-icon icon="cloud-slash" class="text-white mr-3"></b-icon>
        <span class="text-white">Offline</span>
    </div>
</template>

<script>
export default {
    computed: {
        indicateShow: function(){
            return (process.env.VUE_APP_LOCAL_PHP === '1' || process.env.VUE_APP_LOCAL_RESOURCES === '1') ? true : false
        }
    }
}
</script>

<style lang="sass" scoped>
div
    bottom: 0
    left: 0
    // width: 500px
    right: 0
    z-index: 1001
    height: 36px
    border-color: #dc3545 !important
</style>